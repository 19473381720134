import { Header } from "../../components/Header"
import { Link } from "react-router-dom"
import vieux from "../../assets/vieux.jpg"
import camera from "../../assets/Accueil/camera.png"
import marionnette from "../../assets/Accueil/marionnette.png"
import plus from "../../assets/plus.png"
import mario from "../../assets/mario.jpg"
import { Helmet } from 'react-helmet';

export const Presentation = () => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
      };
    return(
        <div className="w-full flex flex-col justify-center items-center lg:text-2xl text-lg">
            <Helmet>
                <title>D.H. Quartz | Présentation de l'entreprise</title>
                <meta name="description" content="Notre entreprise D.H. Quartz a été fondée il y a 35 ans par notre père, Christian Durut, à Charleville-Mézières. Auparavant technicien chez les Anciens Ets Lebrun, il décide de créer son entreprise en 1985." />
                <link rel="canonical" href="https://dhquartz.com" />
                {/* Facebook */}
                <meta property="og:type" content="Website" />
                <meta property="og:title" content="D.H. Quartz | Présentation de l'entreprise" />
                <meta property="og:description" content="Notre entreprise D.H. Quartz a été fondée il y a 35 ans par notre père, Christian Durut, à Charleville-Mézières. Auparavant technicien chez les Anciens Ets Lebrun, il décide de créer son entreprise en 1985." />
                {/* Twitter */}
                <meta name="twitter:creator" content="DH Quartz" />
                <meta name="twitter:card" content="Website" />
                <meta name="twitter:title" content="D.H. Quartz | Présentation de l'entreprise" />
                <meta name="twitter:description" content="Notre entreprise D.H. Quartz a été fondée il y a 35 ans par notre père, Christian Durut, à Charleville-Mézières. Auparavant technicien chez les Anciens Ets Lebrun, il décide de créer son entreprise en 1985." />
            </Helmet>
            <Header title="À la découverte de D.H. Quartz" />
            <div className="w-[80%] flex lg:flex-row flex-col items-center justify-between lg:space-y-0 space-y-8">
                <div className="lg:w-[45%] w-full flex justify-center items-center">
                    <img src={vieux} alt="Vieux" className="w-[90%]" />
                </div>
                <div className="lg:w-[50%] w-full">
                    <p className="italic">Notre entreprise D.H. Quartz a été fondée il y a 35 ans par notre père, Christian Durut, à Charleville-Mézières. Auparavant technicien chez les Anciens Ets Lebrun, il décide de créer son entreprise en 1985. Depuis lors, il n'a cessé de consacrer son énergie et son expertise au développement de la société et à la satisfaction des clients.</p>
                </div>
            </div>
            <div className="w-full bg-black text-white flex justify-center items-center lg:pt-28 pt-16 lg:pb-20 pb-10 my-16 relative" id="plus">
                <div className="w-[80%] flex lg:flex-row flex-col items-center justify-between space-y-6 lg:space-y-0">
                    <div className="flex flex-col justify-between items-start lg:w-[50%] w-full space-y-10">
                        <h2 className="lg:text-5xl text-3xl font-semibold">Du temps à la sécurité : L'expansion d'une entreprise polyvalente</h2>
                        <p>D’abord axée sur l’horlogerie industrielle et sur tout ce qui concerne l’entretien et la réparation des cloches, des églises et des cadrans que vous retrouvez dans vos villes et vos villages ; il développe son activité en mettant à profit ses compétences et celles de ses techniciens en incorporant une nouvelle branche à son entreprise : l’installation, la réparation et la maintenance de systèmes de sécurité.</p>
                    </div>
                    <div className="lg:w-[45%] w-full flex justify-center items-center">
                        <img src={camera} alt="Camera" className="w-[90%]" />
                    </div>
                </div>
                <a href="#plus" className="bg-[#F5F5F5] rounded-full lg:w-[150px] lg:h-[150px] w-[70px] h-[70px] absolute flex justify-center items-center lg:-top-[75px] -top-[35px]">
                    <img src={plus} alt="Plus" className="w-[40%]" />
                </a>
            </div>
            <div className="w-[80%] flex lg:flex-row flex-col-reverse items-center justify-between">
                <div className="lg:w-[45%] w-full flex justify-center items-center lg:pt-0 pt-6">
                    <img src={mario} alt="Marionnette" className="w-[90%] object-cover max-h-[500px]" />
                </div>
                <div className="flex flex-col justify-between items-start lg:w-[50%] w-full lg:space-y-10 space-y-6">
                    <h2 className="lg:text-5xl text-3xl font-semibold">Une entreprise familiale au cœur des Ardennes</h2>
                    <p>Notre entreprise existe sur le territoire Ardennais depuis de nombreuses années, elle a su trouver sa place et la garder. Elle est portée par des hommes qui connaissent leur métier. Aujourd’hui, notre devoir est de la faire perdurer dans le temps, tout en innovant et en l’ancrant encore plus dans la modernité. Nous sommes une petite entreprise familiale, locale, avec un savoir-faire artisanal et nous sommes au plus proche de vous.</p>
                </div>
            </div>
            <div className="w-full bg-black text-white flex justify-center items-center lg:pt-28 pt-12 lg:pb-20 pb-10 lg:mt-16 mt-8">
                <div className="lg:w-[60%] w-[80%] flex flex-col items-center justify-center text-center space-y-10">
                    <p>“Nos objectifs sont de continuer de vous protéger, vous et vos biens, mais également de garantir le bon fonctionnement et l’entretien de notre superbe patrimoine religieux, architectural et rural français.”</p>
                    <div>
                        <Link to="/contact" href="/contact" onClick={scrollToTop} className="text-white bg-[#B6872D] px-5 py-2 rounded-2xl">Nous contacter</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}