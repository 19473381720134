import { Header } from "../../components/Header"
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react"
import axios from 'axios';
import { Helmet } from 'react-helmet';

export const Actualite = () => {
    const {id} = useParams()
    let [postState, setPostState] = useState([])
    let [galeries, setGaleries] = useState([])
    let [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetch(`https://api.dhquartz.com/api/actualites/${id}`)
        .then((response) => {
            response = response.json()
            response.then((result) => {
                setPostState(result)
                setIsLoading(false)
            })})
    }, [])

    useEffect(() => {
        fetch(`https://api.dhquartz.com/api/images`)
        .then((response) => {
            response = response.json()
            response.then((result) => {
                setGaleries(result['hydra:member'])
                setIsLoading(false)
            })})
    }, [])

    return(
        <div className="w-full flex flex-col justify-center items-center">
            <Helmet>
                <title>{postState.title} | D.H. Quartz</title>
            </Helmet>
            <Header title={postState.title} />
            <div className="w-[80%] flex justify-start">
                <Link to={'/actualites'} className="bg-[#B4842C] text-white rounded-full py-2 px-5">Retour</Link>
            </div>
            <div className="w-[85%] grid lg:grid-cols-2 grid-cols-1 my-10 gap-x-20 lg:gap-y-0 gap-y-6">
                <div>
                    <img src={"https://api.dhquartz.com/build/images/" + postState.image} alt="Test" className='w-[100%]' />
                </div>
                <div className="flex flex-col space-y-6">
                    <p className="lg:text-lg text-base" dangerouslySetInnerHTML={{ __html: postState.description }} />
                    <p className='lg:text-lg text-base text-[#B4842C]'>{(new Date(postState.createdAt)).toLocaleDateString()}</p>
                </div>
            </div>
            <div className="relative w-full flex flex-col items-center justify-center py-16 text-white">
                <div className="w-[85%] grid lg:grid-cols-4 grid-cols-1 gap-x-16 gap-y-10 mb-10">
                    {isLoading ? "Chargement en cours" : galeries.map(galerie => (
                        <img src={postState.gallery === "/api/images/"+galerie.id ? "Image en cours de chargement" : "https://api.dhquartz.com/build/images/"+galerie.path } alt="" />
                    ))}
                </div>
                <div className="mb-10 w-[80%] text-center">
                    <h3 className="lg:text-3xl text-xl font-semibold">Une question ? Un projet ?</h3>
                </div>
                <div>
                    <Link to={'/contact'} className="bg-[#B4842C] text-white rounded-full py-2 px-5">Nous contacter</Link>
                </div>
                <div className="bg-black w-full absolute h-2/3 -z-50 bottom-0"></div>
            </div>
        </div>
    )
}