import { Header } from "../../components/Header"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet';

export const Mentions = () => {
    return(
        <div className="w-full flex flex-col justify-center items-center lg:text-2xl text-lg">
            <Helmet>
                <title>Mentions légales | D.H. Quartz</title>
            </Helmet>
            <Header title="Mentions légales"/>
            <div class="w-5/6 flex lg:flex-col flex-col items-center justify-between mt-16 mb-16 space-y-8">
                <p class="w-full">Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l’économie numérique, il est précisé aux utilisateurs du site globalaxe.fr l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi.</p>
                <h3 class="font-bold text-3xl w-full">Propriétaire</h3>
                <p class="w-full">SARL DURUT HAOUY QUARTZ - n°Siren 332787381 - n°Siret 33278738100026 - n° TVA Intracommunautaire FR34332787381 - n° RCS Sedan B 332 787 381 - 	33 Rue d'étion, 08000 CHARLEVILLE-MEZIERES</p>
                <h3 class="font-bold text-3xl w-full">Conception et réalisation</h3>
                <p class="w-full">Agence de Communication COMSEA - Site Internet : www.comsea.fr - Email : contact@comsea.fr</p>
                <h3 class="font-bold text-3xl w-full">Responsable de publication</h3>
                <p class="w-full">Véronique DURUT - contact1@dhquartz.com</p>
                <h3 class="font-bold text-3xl w-full">Hébergeur</h3>
                <p class="w-full">Le site omnitechnique.com est hébergé par la société :</p>
                <p class="w-full">OVH, 2 RUE KELLERMANN 59100 ROUBAIX</p>
                <h3 class="font-bold text-3xl w-full">Propriété Intellectuelle</h3>
                <p class="w-full">Le présent site Internet pris dans sa globalité et chacun des éléments qui le composent pris indépendamment, notamment les programmes et développements spécifiques et les contenus incluant des données, textes, images fixes ou animées, logotypes, sons, graphiques, fichiers, sont la propriété exclusive du responsable de la publication, du créateur du site Internet ou de tiers qui lui ont concédé une licence. Toute représentation totale ou partielle du site ou de l’un des éléments qui le composent sans l’autorisation expresse du titulaire des droits de propriété intellectuelle est interdite et constituerait une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle.</p>
                <h3 class="font-bold text-3xl w-full">Nous contacter</h3>
                <p class="w-full">par email : contact1@dhquartz.com</p>
            </div>
        </div>
    )
}