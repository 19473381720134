import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import icon from "../../assets/icon.svg"
import horloge from "../../assets/horloge.svg"
import cadenas from "../../assets/cadenas.svg"
import camera from "../../assets/camera.svg"
import alarme from "../../assets/alarme.svg"
import cloche from "../../assets/cloche.png"
import ajax from "../../assets/ajax.png"
import digicode from "../../assets/digicode.png"
import heure from "../../assets/horloge.png"
import bell from "../../assets/bell.png"
import { Pagination, Stack, ThemeProvider, createTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Helmet } from 'react-helmet';
import Banderole from '../../components/Banderole ';

const theme = createTheme({
    palette: {
        primary:{
            main: '#B6872D',
            secondary: '#FFFFFF',
            contrastText: '#FFFFFF'
        }
    }
})

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    '@media (max-width: 1024px)': {
        // Styles spécifiques à une largeur d'écran maximale de 600 pixels
        width: 350, // Par exemple, changer la largeur à 100% pour les petits écrans
        p: 2,
      },
  };

function Accueil() {
    const [isLoading, setIsLoading] = useState(true)
    const [actualites, setActualites] = useState([])
    let [realisations, setRealisations] = useState([])
    const [page, setPage] = useState(1)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [openModals, setOpenModals] = useState({});

    useEffect(() => {
        fetch(`https://api.dhquartz.com/api/actualites`)
        .then((response) => response.json())
        .then((result) => {
            const fetchedActualites = result['hydra:member'];
            const sortedActualites = fetchedActualites.slice().sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateB - dateA;
        });
        setActualites(sortedActualites);
        setIsLoading(false)
        })
        .catch((error) => {
            console.error(error);
    })
    }, [])

    useEffect(() => {
        fetch(`https://api.dhquartz.com/api/realisations`)
        .then((response) => {
            response = response.json()
            response.then((result) => {
                setRealisations(result['hydra:member'])
                setIsLoading(false)
            })})
    }, [])

    let constantValue = 1;

    if (windowWidth > 1490) {
        constantValue = 2;
    } else if (windowWidth > 1024) {
        constantValue = 1;
    }

    const handleChangePage = (event, value) => {
        setPage(value)
    }

    const startIndex = (page - 1) * constantValue
    const endIndex = startIndex + constantValue

    const toggleModal = (realisationId) => {
        setOpenModals((prevOpenModals) => ({
            ...prevOpenModals,
            [realisationId]: !prevOpenModals[realisationId],
        }));
    };

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth"});
      };

    return (
        <div className="w-full flex flex-col lg:text-2xl text-lg items-center justify-center">
            <Helmet>
                <title>D.H. Quartz | Vivre à l'heure et en sécurité</title>
                <meta name="description" content="Venez découvrir DH Quartz, votre expert sur l'installation/pose alarme incendie, la sécurité incendie, les PPMS et les systèmes de sécurité incendie-intrusion-alarme video-videoprotection dans les Ardennes à Charleville-Mézières." />
                <link rel="canonical" href="https://dhquartz.com" />
                {/* Facebook */}
                <meta property="og:type" content="Website" />
                <meta property="og:title" content="D.H. Quartz | Vivre à l'heure et en sécurité" />
                <meta property="og:description" content="Venez découvrir DH Quartz, votre expert sur l'installation/pose alarme incendie, la sécurité incendie, les PPMS et les systèmes de sécurité incendie-intrusion-alarme video-videoprotection dans les Ardennes à Charleville-Mézières." />
                {/* Twitter */}
                <meta name="twitter:creator" content="DH Quartz" />
                <meta name="twitter:card" content="Website" />
                <meta name="twitter:title" content="D.H. Quartz | Vivre à l'heure et en sécurité" />
                <meta name="twitter:description" content="Venez découvrir DH Quartz, votre expert sur l'installation/pose alarme incendie, la sécurité incendie, les PPMS et les systèmes de sécurité incendie-intrusion-alarme video-videoprotection dans les Ardennes à Charleville-Mézières." />
            </Helmet>
            <div className="w-full flex justify-center items-center lg:mt-28 mt-4 mb-12 relative">
                <div className="w-11/12 flex lg:flex-row flex-col justify-around lg:items-start items-center">
                    <div className="lg:w-[45%] w-full lg:text-xl text-lg lg:space-y-8 space-y-5">
                        <h1 className="absolute invisible">DH Quartz</h1>
                        <h2 className="font-bold lg:text-7xl text-4xl">Nous vous aidons à vivre à l'heure et en sécurité</h2>
                        <div className="flex flex-col space-y-3">
                            <p>Notre société est spécialisée dans différents domaines : la gestion du temps, des accès, de la sécurité et du patrimoine. 
                                Explorez dès à présent l'ensemble de nos services !</p>
                        </div>
                        <div>
                            <Link to="/presentation" className="text-white bg-[#B6872D] px-5 py-2 rounded-3xl">En savoir plus</Link>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-[70%] flex lg:justify-start justify-center lg:items-start items-center lg:mt-0 mt-10">
                        <img src={icon} alt="Icon" />
                    </div>
                </div>
                <img src={horloge} alt="Horloge" className="absolute lg:top-[15%] -bottom-[5%] lg:left-[50%] left-[10%] lg:w-[100px] w-[50px]" />
                <img src={cadenas} alt="Cadenas" className="absolute lg:-top-[25%] bottom-[30%] lg:left-[63%] left-[15%] lg:w-[100px] w-[50px]" />
                <img src={camera} alt="Camera" className="absolute lg:-top-[20%] bottom-[37%] left-[82%] lg:w-[100px] w-[50px]" />
                <img src={alarme} alt="Alarme" className="absolute lg:top-[19%] bottom-[5%] lg:left-[91%] left-[85%] lg:w-[100px] w-[50px]" />
            </div>
            <div className="w-full flex flex-col items-center justify-center pt-10 relative space-y-16 pb-20">
                <div className="lg:w-[75%] w-[90%] grid lg:grid-cols-4 grid-cols-2 lg:gap-x-16 gap-x-5 gap-y-4 lg:gap-y-0">
                    <Link to='/gestion-des-temps-alarmes-incendies-et-ppms' className="bg-[#F5F5F5] rounded lg:py-12 py-4 px-2 flex flex-col items-center lg:space-y-6 space-y-2">
                        <img src={horloge} alt="Horloge" className="lg:h-[180px] h-[110px]" />
                        <p className="font-semibold lg:text-2xl text-xl text-center">Gestion du temps</p>
                    </Link>
                    <Link to='/gestion-des-acces' className="bg-[#F5F5F5] rounded lg:py-12 py-4 px-2 flex flex-col items-center lg:space-y-6 space-y-2">
                        <img src={cadenas} alt="Cadena" className="lg:h-[180px] h-[110px]" />
                        <p className="font-semibold lg:text-2xl text-xl text-center">Gestion de l'accès</p>
                    </Link>
                    <Link to='/gestion-de-la-securite' className="bg-[#F5F5F5] rounded lg:py-12 py-4 px-2 flex flex-col items-center lg:space-y-6 space-y-2">
                        <img src={camera} alt="Caméra" className="lg:h-[180px] h-[110px]" />
                        <p className="font-semibold lg:text-2xl text-xl text-center">Gestion de la sécurité</p>
                    </Link>
                    <Link to='/gestion-du-patrimoine' className="bg-[#F5F5F5] rounded lg:py-12 py-4 px-2 flex flex-col items-center lg:space-y-6 space-y-2">
                        <img src={cloche} alt="Cloche" className="lg:h-[180px] h-[110px]" />
                        <p className="font-semibold lg:text-2xl text-xl text-center">Gestion du patrimoine</p>
                    </Link>
                </div>
                <div className="w-[80%] flex flex-col items-center justify-center text-white space-y-6">
                    <h2 className="font-bold lg:text-4xl text-3xl">Notre histoire, votre satisfaction : de génération en génération.</h2>
                    <div className="w-1/4 bg-[#B6872D] h-[3px]"></div>
                    <div className="w-full flex flex-col justify-center text-center space-y-2">
                        <p>Notre société est une entreprise familiale fondée en 1985 par notre père, Christian Durut à Charleville-Mézières. Depuis 2017, nous avons rejoint l'aventure et nous avons à cœur de la voir perdurer. Nous sommes au service des particuliers, des professionnels et des collectivités locales. Lorsque vous faîtes appel à nous, nos techniciens vous accompagnent point par point, de la visite sur site à l'élaboration du devis gratuit jusqu'à la pose du matériel, et même, si vous le souhaitez à la maintenance de ce dernier. Nos champs d'expertises vont de la gestion des accès et des temps à la gestion de la sécurité des biens et des personnes, jusqu'au métier ancestrale de campaniste (ingénieur des clochers).</p>
                    </div>
                </div>
                <h2 className="font-bold lg:text-4xl text-3xl w-[80%] text-white">Nos dernières actualités</h2>
                <div className="lg:w-[65%] w-[80%] grid lg:grid-cols-2 grid-cols-1 gap-40">
                    {isLoading ? 'Chargement en cours' : actualites.slice(startIndex, endIndex).map(actualite => (
                        <div className='rounded-xl w-full bg-[#F5F5F5]'>
                            <img src={"https://api.dhquartz.com/build/images/" + actualite.image} alt="Test" className='w-full rounded-t-xl object-cover h-[250px]' />
                            <div className='py-4 px-4 space-y-3'>
                                <p className='lg:text-lg text-sm text-[#B4842C]'>{(new Date(actualite.createdAt)).toLocaleDateString()}</p>
                                <h3 className='font-semibold lg:text-4xl text-3xl'>{actualite.title}</h3>
                                <p className="line-clamp-3 lg:text-lg text-sm" dangerouslySetInnerHTML={{ __html: actualite.description }} />
                                <Link to={`/actualite/${actualite.id}`} className='lg:text-lg text-sm text-[#B4842C]'>Voir l'article →</Link>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex flex-row items-center justify-center space-x-2">
                    <ThemeProvider theme={theme}>
                            <Stack spacing={2} justifyContent="center" py={1} px={4} className='bg-white shadow-2xl rounded-full'>
                                {isLoading ? 
                                <div>
                                    Chargement en cours
                                </div> 
                                : 
                                <Pagination 
                                    count={2}
                                    page={page}
                                    onChange={handleChangePage}
                                    color='primary'
                                    
                                />
                                }
                            </Stack>
                        </ThemeProvider>
                </div>
                <div>
                    <Link to="/actualites" href="/" onClick={scrollToTop} className="text-white bg-[#B6872D] px-5 py-2 rounded-2xl">Voir plus d'actualités</Link>
                </div>
                <div className="absolute w-full bg-black h-[55%] -z-50 top-52"></div>
            </div>
            <div className="bg-black w-full py-20 text-white flex justify-center items-center">
                <div className="lg:w-[80%] w-[85%] grid lg:grid-cols-3 grid-cols-1 gap-x-16 gap-y-12">
                    <div className="flex flex-col space-y-6 lg:col-span-2 col-span-1">
                        <h2 className="font-bold lg:text-4xl text-3xl">Nos métiers</h2>
                        <p>Chez D.H. Quartz, nous sommes fiers de mettre à votre disposition notre expertise dans quatre domaines essentiels. Chacun de ces métiers représente une pièce maîtresse de notre engagement envers nos clients, et nous nous efforçons constamment d'offrir des solutions innovantes et efficaces pour répondre aux besoins de nos clients sur Charleville-Mézières et ses alentours.</p>
                    </div>
                    <div className="flex flex-col items-center justify-between space-y-2">
                        <img src={heure} alt="Ajax" className="max-w-[300px] max-h-[200px]" />
                        <div className="w-full flex flex-col space-y-4">
                            <h4 className="font-semibold lg:text-3xl text-2xl">Gestion du temps</h4>
                            <p className="lg:line-clamp-2 line-clamp-3">Quand on a une entreprise employant un certain nombre d'employés, cela peut être difficile de suivre la ponctualité de chacun. Grâce à notre large de gamme de système de contrôle du temps nous pouvons offrir des solutions qui vous seront adaptées. Pour être tous à la même heure, nous nous occupons de l'installation d'affichage d'heure simple ou en réseau, filaire ou sans fil DHF pour votre entreprise, terrain de sport ou affichage public.Nous sommes aussi spécialistes dans la pose d'alarme incendie notamment dans les établissements recevant du public ( écoles, collèges, lycées, ect.)</p>
                            <Link to="/gestion-des-temps-alarmes-incendies-et-ppms#top" href="/gestion-des-temps-alarmes-incendies-et-ppms#top" className="text-[#B6872D] hover:underline" onClick={goToTop}>En savoir plus</Link>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-between space-y-2">
                        <img src={digicode} alt="Digicode" className="max-w-[300px] max-h-[200px]" />
                        <div className="w-full flex flex-col space-y-4">
                            <h4 className="font-semibold lg:text-3xl text-2xl">Gestion de l'accès</h4>
                            <p className="lg:line-clamp-2 line-clamp-3">Un contrôle d'accès est idéal pour assurer une véritable sécurité de votre bâtiment. Vous souhaitez sécuriser votre domicile, entreprise ou commerce avec un portail automatique ; pour votre tranquillité d'esprit vous voulez installer un portier électronique à badge voire biométrique à l'entrée de votre bâtiment ; nos artisans saurons vous donner des conseils sur mesure et réaliser l'ensemble des travaux nécessaires. Après l'installation du matériel nous assurons la maintenance de vos barrières, portails et serrures électriques.</p>
                            <Link to="/gestion-des-acces" href="/gestion-des-acces" className="text-[#B6872D] hover:underline" onClick={goToTop}>En savoir plus</Link>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-between space-y-2">
                        <img src={ajax} alt="Sécurité" className="max-w-[300px] max-h-[200px]" />
                        <div className="w-full flex flex-col space-y-4">
                            <h4 className="font-semibold lg:text-3xl text-2xl">Gestion de la sécurité</h4>
                            <p className="lg:line-clamp-2 line-clamp-3">La sécurité de nos maisons et de nos biens en général est une préoccupation que nous avons tous. L'entreprise D.H. Quartz est partenaire de l'expert de référence Sécuritas, nous sommes donc capables de réaliser toutes vos demandes pour la sécurisation de vos biens immobiliers. De l'alarme anti-intrusion à la surveillance continue par réseau de caméra de surveillance nous sommes tout à fait qualifiés pour vous conseiller et installer un système personnalisé et sur mesure. Nous vous accompagnerons ensuite en ce qui concerne l'entretien du matériel installé.</p>
                            <Link to="/gestion-de-la-securité" href="/gestion-de-la-securité" className="text-[#B6872D] hover:underline" onClick={goToTop}>En savoir plus</Link>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-between space-y-2">
                        <img src={bell} alt="Bell" className="max-w-[300px] max-h-[200px]" />
                        <div className="w-full flex flex-col space-y-4">
                            <h4 className="font-semibold lg:text-3xl text-2xl">Gestion du patrimoine</h4>
                            <p className="lg:line-clamp-2 line-clamp-3">C'est un métier rare et souvent peu connu. Campaniste c'est celui qui crée, répare et entretien les milliers de cloches et d'horloges qui ornent et font vivre nos églises et nos édifices aussi bien dans les grandes villes que dans les villages. Nos techniciens sont à la fois horlogers, électriciens, mais surtout électromécaniciens et électroniciens.</p>
                            <Link to="/gestion-du-patrimoine" href="/gestion-du-patrimoine" className="text-[#B6872D] hover:underline" onClick={goToTop}>En savoir plus</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col items-center justify-center relative py-16">
                <div className="w-[80%] flex flex-col justify-center items-center space-y-10">
                    <div className='flex lg:flex-row flex-col justify-between lg:items-center items-start w-full lg:space-y-0 space-y-4'>
                        <h2 className="font-bold lg:text-4xl text-3xl">Nos dernières réalisations</h2>
                        <div>
                            <Link to="/photos" href="/photos" onClick={scrollToTop} className="text-white bg-[#B6872D] px-5 py-2 rounded-2xl">Voir toutes nos réalisations</Link>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-3 grid-cols-1 gap-x-40 gap-y-10 w-full">
                    {isLoading ? "Chargement en cours" : realisations.slice(-3).reverse().map(realisation => (
                        <ThemeProvider theme={theme}>
                            <div className="flex justify-center items-center w-full h-[250px] relative transition ease-in-out hover:scale-110 duration-300 hover:shadow-2xl">
                                <Button onClick={() => toggleModal(realisation.id)} tabIndex={realisation.id} id={realisation.title} className='w-full h-full object-cover'><img src={"https://api.dhquartz.com/build/images/" + realisation.image} alt="Test" className='w-full h-full object-cover' /></Button>
                                <Modal
                                    open={openModals[realisation.id] || false}
                                    onClose={() => toggleModal(realisation.id)}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    id={realisation.title}
                                    tabIndex={realisation.id}
                                >
                                    <Box sx={style} className="flex lg:flex-row flex-col justify-between items-center">
                                        <div className="lg:w-[50%] w-full lg:mb-0 mb-4">
                                            <img src={"https://api.dhquartz.com/build/images/" + realisation.image} alt="Test" className='w-full max-h-[800px] object-cover' />
                                        </div>
                                        <div className="lg:w-[45%] w-full lg:space-y-6 space-y-4">
                                            <p className="lg:text-2xl text-xl font-bold">{realisation.title}</p>
                                            <p className="lg:text-lg text-base" dangerouslySetInnerHTML={{ __html: realisation.description }} />
                                        </div>
                                    </Box>
                                </Modal>
                            </div>
                        </ThemeProvider>
                    )
                )}
                    </div>
                    <h3 className="text-white lg:text-3xl text-xl font-semibold">Une question ? Un projet ?</h3>
                    <div>
                        <Link to="/contact" href="/contact" className="text-white bg-[#B6872D] px-5 py-2 rounded-2xl">Nous contacter</Link>
                    </div>
                </div>
                <div className="absolute w-full bg-black h-[45%] -z-50 bottom-0"></div>
            </div>
            <Banderole />
        </div>
    )
}

export default Accueil