import amcor from "../../assets/Logo/logo-transparent-small-opt.png"
import cci from "../../assets/Logo/telechargement.png"
import critt from "../../assets/Logo/LOGO_SOURCE-27-1-1024x502.png"
import dossot from "../../assets/Logo/logo_dsptech_w150.jpg"
import eiffage from "../../assets/Logo/2018-EE.jpg"
import elecmaster from "../../assets/Logo/elec-master-logo.jpg"
import eqiom from "../../assets/Logo/logo-eqiom-crh.png"
import fdea from "../../assets/Logo/logo-_3_.png"
import faber from "../../assets/Logo/faber_rgb-2048x1068.jpg.webp"
import ferracin from "../../assets/Logo/Logo.png"

const Banderole = () => {
    return(
        <marquee direction="left">
            <div class="flex flex-row justify-between items-center w-[90%] my-8 mx-auto">
                <a href="https://www.amcor.com/stelvin/fr" target="_blank" className="w-[7%]"><img src={amcor} alt="Logo d'Amcor" className="w-full" /></a>
                <a href="https://www.cci.fr/" target="_blank" className="w-[7%]"><img src={cci} alt="Logo de la CCI" className="w-full" /></a>
                <a href="https://critt-mi.com/" target="_blank" className="w-[7%]"><img src={critt} alt="Logo du CRITT" className="w-full" /></a>
                <a href="https://dsptech.fr/" target="_blank" className="w-[7%]"><img src={dossot} alt="Logo de Dossot" className="w-full" /></a>
                <a href="https://www.eiffageenergiesystemes.com/home" target="_blank" className="w-[7%]"><img src={eiffage} alt="Logo d'Eiffage" className="w-full" /></a>
                <a href="https://www.master-group.fr/elec-master.html" target="_blank" className="w-[7%]"><img src={elecmaster} alt="Logo d'Elec Master" className="w-full" /></a>
                <a href="https://www.eqiom.com/" target="_blank" className="w-[7%]"><img src={eqiom} alt="Logo d'Eqiom" className="w-full" /></a>
                <a href="https://www.fdea08.fr/" target="_blank" className="w-[7%]"><img src={fdea} alt="Logo de la FDEA" className="w-full" /></a>
                <a href="https://www.faber.fr/" target="_blank" className="w-[7%]"><img src={faber} alt="Logo de Faber" className="w-full" /></a>
                <a href="http://www.ferracin.fr/index.html" target="_blank" className="w-[7%]"><img src={ferracin} alt="Logo de Ferracin" className="w-full" /></a>

            </div>
        </marquee>

    )
}
export default Banderole