import { Header } from "../../components/Header"
import map from "../../assets/Contact/map.png"
import mail from "../../assets/Contact/mail.png"
import tel from "../../assets/Contact/tel.png"
import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet';

function Contact() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        acceptTerms: false,
      });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const fieldValue = type === 'checkbox' ? checked : value;
    
        setFormData({
          ...formData,
          [name]: fieldValue,
        });
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true)
        try {
          await axios.post('https://api.dhquartz.com/contact', formData);
          toast.success('Message envoyé avec succès!');
        } catch (error) {
          toast.error('Erreur lors de l\'envoi du message', error);
        } finally {
            setIsSubmitting(false)
        }
      };

    console.log(formData)

    return (
        <div className="w-full flex-col justify-center items-center lg:text-2xl text-lg">
            <Helmet>
                <title>Contactez-nous ! | D.H. Quartz</title>
            </Helmet>
            <Header title="Une question ? Un projet ? Contactez-nous !" />
            <ToastContainer className="text-base" />
            <div className="w-full flex flex-col items-center justify-center pt-10 relative space-y-16 pb-20">
                <div className="lg:w-[70%] w-[50%] grid lg:grid-cols-3 grid-cols-1 lg:gap-x-32 gap-x-4 gap-y-6">
                    <div className="bg-[#F5F5F5] rounded py-6 px-2 flex flex-col items-center justify-between space-y-6">
                        <img src={map} alt="Map" className="lg:h-[150px] h-[80px]" />
                        <h3 className="lg:text-2xl text-base text-center">35 Rue d'Etion, 08000 Charleville-Mézières</h3>
                    </div>
                    <div className="bg-[#F5F5F5] rounded py-6 px-2 flex flex-col items-center justify-between space-y-6">
                        <img src={tel} alt="Téléphone" className="lg:h-[150px] h-[80px]" />
                        <h3 className="lg:text-2xl text-base">03.24.56.28.38</h3>
                    </div>
                    <div className="bg-[#F5F5F5] rounded py-6 px-2 flex flex-col items-center justify-between space-y-6">
                        <img src={mail} alt="Mail" className="lg:h-[150px] h-[80px]" />
                        <h3 className="lg:text-2xl text-base">contact1@dhquartz.com</h3>
                    </div>
                </div>
                <div className="lg:w-[60%] w-[80%]">   
                    <form onSubmit={handleSubmit} className="w-full lg:grid flex flex-col lg:grid-cols-2 lg:gap-8 space-y-4 lg:space-y-0">
                        <div className="flex flex-col space-y-4 w-full">
                            <div className="space-y-1">
                                <label htmlFor="lastName" ></label>
                                <input type="text" id="lastName" name="lastName" onChange={handleChange} value={formData.lastName} placeholder="Nom *" className="w-full bg-[#F5F5F5] active:border-[#B6872D] rounded-lg lg:px-4 px-2 lg:py-2 py-1"/>
                            </div>
                            <div className="space-y-1">
                                <label htmlFor="firstName" ></label>
                                <input type="text" id="firstName" name="firstName" onChange={handleChange} value={formData.firstName} placeholder="Prénom *" className="w-full bg-[#F5F5F5] active:border-[#B6872D] rounded-lg lg:px-4 px-2 lg:py-2 py-1"/>
                            </div>
                            <div className="space-y-1">
                                <label htmlFor="email" ></label>
                                <input type="email" id="email" name="email" onChange={handleChange} value={formData.email} placeholder="prenom@nom.fr *" className="w-full bg-[#F5F5F5] active:border-[#B6872D] rounded-lg lg:px-4 px-2 lg:py-2 py-1"/>
                            </div>
                            <div className="space-y-1">
                                <label htmlFor="phone" ></label>
                                <input type="phone" id="phone" name="phone" onChange={handleChange} value={formData.phone} placeholder="Téléphone" className="w-full bg-[#F5F5F5] active:border-[#B6872D] rounded-lg lg:px-4 px-2 lg:py-2 py-1"/>
                            </div>
                            <div className="space-y-1">
                                <label htmlFor="subject" ></label>
                                <input type="text" id="subject" name="subject" onChange={handleChange} value={formData.subject} placeholder="Sujet *" className="w-full bg-[#F5F5F5] active:border-[#B6872D] rounded-lg lg:px-4 px-2 lg:py-2 py-1"/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="space-y-1 h-full">
                                <label htmlFor="message" ></label>
                                <textarea id="message" name="message" onChange={handleChange} value={formData.message} placeholder="Votre message...*" className="w-full bg-[#F5F5F5] active:border-[#B6872D] rounded-lg lg:px-4 px-2 lg:py-2 py-1 h-full"></textarea>
                            </div>
                        </div>
                        <div className="text-white col-span-2 flex flex-col space-y-4">
                            <div className="space-y-1">
                                <label htmlFor="acceptTerms" className="flex items-center">
                                    <input type="checkbox" id="acceptTerms" name="acceptTerms" onChange={handleChange} value={formData.acceptTerms} className="bg-[#F5F5F5] accent-[#B6872D] rounded-lg px-3 py-3" />
                                    <span className="ml-2 lg:text-sm text-xs">En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le cadre de la demande de contact et de la relation commerciale qui peut en découler. <Link to="/politiques" className="hover:text-red-600">*</Link></span>
                                </label>
                            </div>
                            <div className="w-full flex justify-center lg:justify-start">
                                <button type="submit" className="bg-[#B6872D] hover:text-[#B6872D] flex items-center text-white font-normal lg:py-2 py-2 px-6 rounded-lg hover:bg-white transition duration-300" disabled={isSubmitting}>{isSubmitting ? "Envoie en cours..." : "Envoyer"}</button>
                            </div>  
                        </div>
                    </form>
                </div>
                <div className="absolute w-full bg-black h-[80%] -z-50 bottom-0"></div>
            </div>
            <div className="w-full h-[250px]">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2576.407890517724!2d4.71106607651779!3d49.77839397147158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ea0e12d9768e15%3A0xb438521092367dd2!2sD.H.%20Quartz%20SARL!5e0!3m2!1sfr!2sfr!4v1697190816935!5m2!1sfr!2sfr" className="w-full h-full"></iframe>
            </div>
        </div>
    )
}

export default Contact