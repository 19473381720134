import { Header } from "../../components/Header"
import React, { useState, useEffect } from 'react';
import { Pagination, Stack, ThemeProvider, createTheme } from '@mui/material';
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet';

const theme = createTheme({
    palette: {
        primary:{
            main: '#B6872D',
            secondary: '#FFFFFF',
            contrastText: '#FFFFFF'
        }
    }
})

export const Actualites = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [actualites, setActualites] = useState([])
    const [page, setPage] = useState(1)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        fetch(`https://api.dhquartz.com/api/actualites`)
        .then((response) => response.json())
        .then((result) => {
            const fetchedActualites = result['hydra:member'];
            const sortedActualites = fetchedActualites.slice().sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateB - dateA;
        });
        setActualites(sortedActualites);
        setIsLoading(false)
        })
        .catch((error) => {
            console.error(error);
    })
    }, [])

    let constantValue = 2;

    if (windowWidth > 1490) {
        constantValue = 6;
    } else if (windowWidth > 1024) {
        constantValue = 4;
    }

    const handleChangePage = (event, value) => {
        setPage(value)
    }

    const startIndex = (page - 1) * constantValue
    const endIndex = startIndex + constantValue
    

    return(
        <div className="w-full flex flex-col justify-center items-center">
            <Helmet>
                <title>Dernière actualités | D.H. Quartz</title>
            </Helmet>
            <Header title="Nos actualités" />
            <div className="lg:w-[70%] w-[90%] grid lg:grid-cols-2 grid-cols-1 gap-x-16 gap-y-10">
                {isLoading ? 'Chargement en cours' : actualites.slice(startIndex, endIndex).map( actualite => (
                    <div className='rounded-xl w-full bg-[#F5F5F5] flex flex-row'>
                        <img src={"https://api.dhquartz.com/build/images/" + actualite.image} alt="Test" className='w-[30%] rounded-l-xl object-cover h-full' />
                        <div className='py-4 px-4 space-y-3'>
                            <p className='lg:text-lg text-sm text-[#B4842C]'>{(new Date(actualite.createdAt)).toLocaleDateString()}</p>
                            <h3 className='font-semibold lg:text-3xl text-2xl'>{actualite.title}</h3>
                            <p className="line-clamp-3 lg:text-base text-sm" dangerouslySetInnerHTML={{ __html: actualite.description }} />
                            <Link to={`/actualite/${actualite.id}`} className='lg:text-lg text-sm text-[#B4842C]'>Voir l'article →</Link>
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex flex-row items-center justify-center space-x-2 mt-16">
                <ThemeProvider theme={theme}>
                    <Stack spacing={2} justifyContent="center" py={1} px={4} className='bg-white shadow-2xl rounded-full'>
                        {isLoading ? "Chargement en cours" : 
                            <Pagination 
                                count={Math.ceil(actualites.length / constantValue)}
                                page={page}
                                onChange={handleChangePage}
                                color='primary'
                            />
                        }
                    </Stack>
                </ThemeProvider>
            </div>
            <div className="bg-[#B6872D] h-[2px] w-[30%] mt-16 rounded-full"></div>
        </div>
    )
}