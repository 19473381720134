import horloge from "../../assets/horloge.svg"
import cadenas from "../../assets/cadenas.svg"
import camera from "../../assets/camera.svg"
import alarme from "../../assets/alarme.svg"

export const Header = (headerProps) => {
    return(
        <div className="w-full py-8 flex justify-center relative mb-16">
            <h1 className="lg:text-7xl text-4xl font-bold lg:w-[60%] w-[80%] text-center">{headerProps.title}</h1>
            <img src={horloge} alt="Horloge" className="absolute -bottom-[5%] left-[5%] lg:w-[100px] w-[50px]" />
            <img src={cadenas} alt="Cadenas" className="absolute -top-[10%] right-[5%] lg:w-[100px] w-[50px]" />
            <img src={camera} alt="Camera" className="absolute -bottom-[20%] left-[82%] lg:w-[100px] w-[50px]" />
            <img src={alarme} alt="Alarme" className="absolute -top-[15%] left-[15%] lg:w-[100px] w-[50px]" />
        </div>
    )
}