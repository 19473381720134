import { Header } from "../../components/Header"
import { Link } from "react-router-dom"
import plus from "../../assets/plus.png"
import cloche from "../../assets/bell.png"
import eglise from "../../assets/Metiers/eglise.png"
import clocher from "../../assets/Metiers/clocher.png"
import clocher2 from "../../assets/Metiers/clocher2.jpg"
import eglise2 from "../../assets/Metiers/eglise2.jpg"
import { Helmet } from 'react-helmet';
import bell from "../../assets/bell.png"
import ajax from "../../assets/ajax.png"
import digicode from "../../assets/digicode.png"
import heure from "../../assets/horloge.png"
import gihec from "../../assets/Metiers/gihec.png"

export const Patrimoine = () => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
      };
      const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    return(
        <div className="w-full flex flex-col justify-center items-center lg:text-2xl text-lg">
            <Helmet>
                <title>D.H. Quartz | Gestion du patrimoine</title>
                <meta name="description" content="C'est un métier rare et souvent peu connu. Campaniste c'est celui qui crée, répare et entretien les milliers de cloches et d'horloges qui ornent et font vivre nos églises et nos édifices aussi bien dans les grandes villes que dans les villages." />
                <link rel="canonical" href="https://dhquartz.com" />
                {/* Facebook */}
                <meta property="og:type" content="Website" />
                <meta property="og:title" content="D.H. Quartz | Gestion du patrimoine" />
                <meta property="og:description" content="C'est un métier rare et souvent peu connu. Campaniste c'est celui qui crée, répare et entretien les milliers de cloches et d'horloges qui ornent et font vivre nos églises et nos édifices aussi bien dans les grandes villes que dans les villages." />
                {/* Twitter */}
                <meta name="twitter:creator" content="DH Quartz" />
                <meta name="twitter:card" content="Website" />
                <meta name="twitter:title" content="D.H. Quartz | Gestion du patrimoine" />
                <meta name="twitter:description" content="C'est un métier rare et souvent peu connu. Campaniste c'est celui qui crée, répare et entretien les milliers de cloches et d'horloges qui ornent et font vivre nos églises et nos édifices aussi bien dans les grandes villes que dans les villages." />
            </Helmet>
            <Header title="Gestion du patrimoine"/>
            <div className="w-[80%] flex lg:flex-row flex-col items-center justify-between">
                <div className="lg:w-[45%] w-full flex justify-center items-center">
                    <img src={cloche} alt="Cloche" className="lg:w-[400px] w-[250px]" />
                </div>
                <div className="lg:w-[50%] w-full flex flex-col space-y-5">
                    <div className="w-full flex flex-col">
                        <p>C'est un métier rare et souvent peu connu. Campaniste c'est celui qui crée, répare et entretien les milliers de cloches et d'horloges qui ornent et font vivre nos églises et nos édifices aussi bien dans les grandes villes que dans les villages. Nos techniciens sont à la fois horlogers, électriciens, mais surtout électromécaniciens et électroniciens.</p>
                        <p className="italic">« Les campanistes sont tous praticiens d'un métier complexe, artisanal et culturel ; la conception, la réalisation, l'installation, la restauration, l'entretien des cloches et horloges d'édifices".</p>
                    </div>
                    <img src={gihec} alt="Gihec" className="w-[400px]" />
                </div>
            </div>
            <div className="w-full bg-black text-white flex justify-center items-center lg:pt-28 pt-16 lg:pb-20 pb-12 lg:mb-16 mb-10 lg:mt-24 mt-16 relative" id="plus">
                <div className="w-[80%] flex lg:flex-row flex-col items-center justify-between">
                    <div className="flex flex-col justify-between items-start lg:w-[50%] w-full lg:space-y-10 space-y-6">
                        <h2 className="lg:text-5xl text-3xl font-semibold">Restauration et entretien d'horloges d'églises : notre expertise à votre service.</h2>
                        <p>Vous êtes maires et vous souhaitez que les moteurs de volée, de tintements, les minuteries électriques de cadrans d'horloge de commande et les coffrets électriques de vos églises soient restaurés, réparés ou changés ? N'hésitez pas à contacter notre entreprise. Depuis plus de 30 ans nous sommes les experts en ce qui concerne l'entretien de votre patrimoine. Nous sommes aussi à même de poser, dépanner et programmer les horloges de commande électriques des moteurs actionnant les cloches et le fonctionnement des aiguilles des cadrans. En France la plupart des édifices religieux appartiennent aux communes, les maires ont le donc le devoir d’entretenir et restaurer si besoin ces biens qui ont à la fois une valeur historique et culturelle.</p>
                    </div>
                    <div className="lg:w-[45%] w-full flex justify-center items-center lg:mt-0 mt-6">
                        <img src={eglise2} alt="Eglise" className="w-[90%]" />
                    </div>
                </div>
                <a href="#plus" className="bg-[#F5F5F5] rounded-full lg:w-[150px] lg:h-[150px] w-[70px] h-[70px] absolute flex justify-center items-center lg:-top-[75px] -top-[35px]">
                    <img src={plus} alt="Plus" className="w-[40%]" />
                </a>
            </div>
            <div className="w-[80%] flex lg:flex-row flex-col-reverse items-center justify-between">
                <div className="lg:w-[45%] w-full lg:mt-0 mt-6 flex justify-center items-center">
                    <img src={clocher2} alt="Clocher" className="w-[90%] max-h-[500px] object-cover" />
                </div>
                <div className="flex flex-col justify-between items-start lg:w-[50%] w-full space-y-10">
                    <p>Avec D.H. Quartz à vos côtés vous aurez un partenaire de confiance pour gérer l'horlogerie mécanique à l'horlogerie électronique. Nous nous occupons de l'installation et de la maintenance de vos affichages horaires. Nous pouvons créer une centrale de commande des cadrans et des sonneries horaires, régler la mise en services de la sonnerie manuelle ou automatique des cloches pour les offices, sonner l'angélus ou le glas grâce à des minuteries électriques ou mécaniques. L'automatisation continue grâce à la pose de moteurs de volée ou de tintements afin d'électrifier vos cloches. Au besoin nous procédons aussi à la restauration de vos carillons.</p>
                </div>
            </div>
            <div className="w-full bg-[#B6872D] text-white flex justify-center items-center lg:pt-28 pt-12 lg:pb-20 pb-10 mt-16">
                <div class="lg:w-[80%] w-[85%] grid lg:grid-cols-3 grid-cols-1 gap-x-16 gap-y-12">
                    <div className="flex flex-col items-center justify-between space-y-2">
                        <img src={digicode} alt="Digicode" className="max-w-[300px] max-h-[200px]" />
                        <div className="w-full flex flex-col space-y-4">
                            <h4 className="font-semibold lg:text-3xl text-2xl">Gestion de l'accès</h4>
                            <p className="lg:line-clamp-2 line-clamp-3">Un contrôle d'accès est idéal pour assurer une véritable sécurité de votre bâtiment. Vous souhaitez sécuriser votre domicile, entreprise ou commerce avec un portail automatique ; pour votre tranquillité d'esprit vous voulez installer un portier électronique à badge voire biométrique à l'entrée de votre bâtiment ; nos artisans saurons vous donner des conseils sur mesure et réaliser l'ensemble des travaux nécessaires. Après l'installation du matériel nous assurons la maintenance de vos barrières, portails et serrures électriques.</p>
                            <Link to="/gestion-des-acces" href="/gestion-des-acces" className=" hover:underline" onClick={goToTop}>En savoir plus</Link>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-between space-y-2">
                        <img src={ajax} alt="Sécurité" className="max-w-[300px] max-h-[200px]" />
                        <div className="w-full flex flex-col space-y-4">
                            <h4 className="font-semibold lg:text-3xl text-2xl">Gestion de la sécurité</h4>
                            <p className="lg:line-clamp-2 line-clamp-3">La sécurité de nos maisons et de nos biens en général est une préoccupation que nous avons tous. L'entreprise D.H. Quartz est partenaire de l'expert de référence Sécuritas, nous sommes donc capables de réaliser toutes vos demandes pour la sécurisation de vos biens immobiliers. De l'alarme anti-intrusion à la surveillance continue par réseau de caméra de surveillance nous sommes tout à fait qualifiés pour vous conseiller et installer un système personnalisé et sur mesure. Nous vous accompagnerons ensuite en ce qui concerne l'entretien du matériel installé.</p>
                            <Link to="/gestion-de-la-securité" href="/gestion-de-la-securité" className=" hover:underline" onClick={goToTop}>En savoir plus</Link>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-between space-y-2">
                        <img src={heure} alt="Ajax" className="max-w-[300px] max-h-[200px]" />
                        <div className="w-full flex flex-col space-y-4">
                            <h4 className="font-semibold lg:text-3xl text-2xl">Gestion du temps</h4>
                            <p className="lg:line-clamp-2 line-clamp-3">Quand on a une entreprise employant un certain nombre d'employés, cela peut être difficile de suivre la ponctualité de chacun. Grâce à notre large de gamme de système de contrôle du temps nous pouvons offrir des solutions qui vous seront adaptées. Pour être tous à la même heure, nous nous occupons de l'installation d'affichage d'heure simple ou en réseau, filaire ou sans fil DHF pour votre entreprise, terrain de sport ou affichage public.Nous sommes aussi spécialistes dans la pose d'alarme incendie notamment dans les établissements recevant du public ( écoles, collèges, lycées, ect.)</p>
                            <Link to="/gestion-des-temps-alarmes-incendies-et-ppms#top" href="/gestion-des-temps-alarmes-incendies-et-ppms#top" className=" hover:underline" onClick={goToTop}>En savoir plus</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-black text-white flex justify-center items-center lg:pt-28 pt-12 lg:pb-20 pb-10 ">
                <div className="w-[60%] flex flex-col items-center justify-center text-center space-y-10">
                    <h3 className="lg:text-3xl text-2xl font-semibold">Une question ? Un projet ? </h3>
                    <div>
                        <Link to="/contact" href="/contact" onClick={scrollToTop} className="text-white bg-[#B6872D] px-5 py-2 rounded-2xl">Contactez-nous</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}