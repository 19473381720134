import { Header } from "../../components/Header"
import { Link } from "react-router-dom"
import digicode from "../../assets/digicode.png"
import portail from "../../assets/Metiers/portail.jpg"
import badge from "../../assets/Metiers/badge.jpg"
import plus from "../../assets/plus.png"
import { Helmet } from 'react-helmet';
import bell from "../../assets/bell.png"
import ajax from "../../assets/ajax.png"
import heure from "../../assets/horloge.png"

export const Acces = () => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
      };
      const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    return(
        <div className="w-full flex flex-col justify-center items-center lg:text-2xl text:lg">
            <Helmet>
                <title>D.H. Quartz | Gestion des accès</title>
                <meta name="description" content="Un contrôle d'accès est idéal pour assurer une véritable sécurité de votre bâtiment. Vous souhaitez sécuriser votre domicile, entreprise ou commerce avec un portail automatique ? Pour votre tranquillité d'esprit vous voulez installer un portier électronique à badge voire biométrique à l'entrée de votre bâtiment ?" />
                <link rel="canonical" href="https://dhquartz.com" />
                {/* Facebook */}
                <meta property="og:type" content="Website" />
                <meta property="og:title" content="D.H. Quartz | Gestion des accès" />
                <meta property="og:description" content="Un contrôle d'accès est idéal pour assurer une véritable sécurité de votre bâtiment. Vous souhaitez sécuriser votre domicile, entreprise ou commerce avec un portail automatique ? Pour votre tranquillité d'esprit vous voulez installer un portier électronique à badge voire biométrique à l'entrée de votre bâtiment ?" />
                {/* Twitter */}
                <meta name="twitter:creator" content="DH Quartz" />
                <meta name="twitter:card" content="Website" />
                <meta name="twitter:title" content="D.H. Quartz | Gestion des accès" />
                <meta name="twitter:description" content="Un contrôle d'accès est idéal pour assurer une véritable sécurité de votre bâtiment. Vous souhaitez sécuriser votre domicile, entreprise ou commerce avec un portail automatique ? Pour votre tranquillité d'esprit vous voulez installer un portier électronique à badge voire biométrique à l'entrée de votre bâtiment ?" />
            </Helmet>
            <Header title="Gestion des accès"/>
            <div className="w-[80%] flex lg:flex-row flex-col items-center justify-between lg:space-y-0 space-y-6">
                <div className="lg:w-[45%] w-full flex justify-center items-center">
                    <img src={digicode} alt="Digicode" className="lg:w-[250px] w-[150px]" />
                </div>
                <div className="lg:w-[50%] w-full">
                    <p>Un contrôle d'accès est idéal pour assurer une véritable sécurité de votre bâtiment. Vous souhaitez sécuriser votre domicile, entreprise ou commerce avec un portail automatique ? Pour votre tranquillité d'esprit vous voulez installer un portier électronique à badge voire biométrique à l'entrée de votre bâtiment ? Nos artisans sauront vous donner des conseils sur mesure et réaliser l'ensemble des travaux nécessaires. Après l'installation du matériel, nous assurons la maintenance de vos barrières, portails et serrures électriques.</p>
                </div>
            </div>
            <div className="w-full bg-black text-white flex justify-center items-center pt-28 pb-20 my-16 relative" id="plus">
                <div className="w-[80%] flex lg:flex-row flex-col items-center justify-between lg:space-y-0 space-y-6">
                    <div className="flex flex-col justify-between items-start lg:w-[50%] w-full lg:space-y-10 space-y-4">
                        <h2 className="lg:text-5xl text-3xl font-semibold">L'Assurance d'un accès sécurisé :</h2>
                        <p>Depuis 30 ans nous nous occupons de l'automatisation et la sécurisation de vos ouvertures afin de vous garantir un accès sécurisé et contrôlé.</p>
                        <p>Pour la pose et l'entretien des portails coulissants, à battants et barrières automatiques à commandes radio ou GSM. Pour l'installation de dispositifs de sécurité comme les photocellules, barres palpeuses ou encore feux de signalisation, nous sommes des experts.</p>
                    </div>
                    <div className="lg:w-[45%] w-full flex justify-center items-center">
                        <img src={portail} alt="Portail" className="w-[90%]" />
                    </div>
                </div>
                <a href="#plus" className="bg-[#F5F5F5] rounded-full lg:w-[150px] lg:h-[150px] w-[70px] h-[70px] absolute flex justify-center items-center lg:-top-[75px] -top-[35px]">
                    <img src={plus} alt="Plus" className="w-[40%]" />
                </a>
            </div>
            <div className="w-[80%] flex lg:flex-row flex-col-reverse items-center justify-between">
                <div className="lg:w-[45%] w-full flex justify-center items-center pt-6 lg:h-[500px] h-[300px] object-cover">
                    <img src={badge} alt="Badge" className="w-[90%] h-full object-cover" />
                </div>
                <div className="flex flex-col justify-between items-start lg:w-[50%] w-full">
                    <p>Pour garantir un fonctionnement optimal de votre entreprise ou collectivité, nous nous chargeons aussi des accès de vos locaux. Nous mettons en place et assurons la maintenance ou la réparation de vos systèmes comme les claviers autonomes, lecteurs de badges, de proximité ou biométriques, des cylindres électroniques, des gâches, serrures électriques, ventouses et bandeaux électromagnétiques.</p>
                </div>
            </div>
            <div className="w-full bg-[#B6872D] text-white flex justify-center items-center lg:pt-28 pt-12 lg:pb-20 pb-10 mt-16">
                <div class="lg:w-[80%] w-[85%] grid lg:grid-cols-3 grid-cols-1 gap-x-16 gap-y-12">
                <div className="flex flex-col items-center justify-between space-y-2">
                        <img src={heure} alt="Ajax" className="max-w-[300px] max-h-[200px]" />
                        <div className="w-full flex flex-col space-y-4">
                            <h4 className="font-semibold lg:text-3xl text-2xl">Gestion du temps</h4>
                            <p className="lg:line-clamp-2 line-clamp-3">Quand on a une entreprise employant un certain nombre d'employés, cela peut être difficile de suivre la ponctualité de chacun. Grâce à notre large de gamme de système de contrôle du temps nous pouvons offrir des solutions qui vous seront adaptées. Pour être tous à la même heure, nous nous occupons de l'installation d'affichage d'heure simple ou en réseau, filaire ou sans fil DHF pour votre entreprise, terrain de sport ou affichage public.Nous sommes aussi spécialistes dans la pose d'alarme incendie notamment dans les établissements recevant du public ( écoles, collèges, lycées, ect.)</p>
                            <Link to="/gestion-des-temps-alarmes-incendies-et-ppms#top" href="/gestion-des-temps-alarmes-incendies-et-ppms#top" className=" hover:underline" onClick={goToTop}>En savoir plus</Link>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-between space-y-2">
                        <img src={ajax} alt="Sécurité" className="max-w-[300px] max-h-[200px]" />
                        <div className="w-full flex flex-col space-y-4">
                            <h4 className="font-semibold lg:text-3xl text-2xl">Gestion de la sécurité</h4>
                            <p className="lg:line-clamp-2 line-clamp-3">La sécurité de nos maisons et de nos biens en général est une préoccupation que nous avons tous. L'entreprise D.H. Quartz est partenaire de l'expert de référence Sécuritas, nous sommes donc capables de réaliser toutes vos demandes pour la sécurisation de vos biens immobiliers. De l'alarme anti-intrusion à la surveillance continue par réseau de caméra de surveillance nous sommes tout à fait qualifiés pour vous conseiller et installer un système personnalisé et sur mesure. Nous vous accompagnerons ensuite en ce qui concerne l'entretien du matériel installé.</p>
                            <Link to="/gestion-de-la-securité" href="/gestion-de-la-securité" className=" hover:underline" onClick={goToTop}>En savoir plus</Link>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-between space-y-2">
                        <img src={bell} alt="Bell" className="max-w-[300px] max-h-[200px]" />
                        <div className="w-full flex flex-col space-y-4">
                            <h4 className="font-semibold lg:text-3xl text-2xl">Gestion du patrimoine</h4>
                            <p className="lg:line-clamp-2 line-clamp-3">C'est un métier rare et souvent peu connu. Campaniste c'est celui qui crée, répare et entretien les milliers de cloches et d'horloges qui ornent et font vivre nos églises et nos édifices aussi bien dans les grandes villes que dans les villages. Nos techniciens sont à la fois horlogers, électriciens, mais surtout électromécaniciens et électroniciens.</p>
                            <Link to="/gestion-du-patrimoine" href="/gestion-du-patrimoine" className=" hover:underline" onClick={goToTop}>En savoir plus</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-black text-white flex justify-center items-center lg:pt-28 pt-12 lg:pb-20 pb-10">
                <div className="w-[60%] flex flex-col items-center justify-center text-center space-y-10">
                    <h3 className="lg:text-3xl text-2xl font-semibold">Une question ? Un projet ? </h3>
                    <div>
                        <Link to="/contact" onClick={scrollToTop} href="/contact" className="text-white bg-[#B6872D] px-5 py-2 rounded-2xl">Contactez-nous</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}