import { Header } from "../../components/Header"
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react"
import { Pagination, Stack, ThemeProvider, createTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Helmet } from 'react-helmet';

const theme = createTheme({
    palette: {
        primary:{
            main: '#B6872D',
            secondary: '#FFFFFF',
            contrastText: '#FFFFFF'
        }
    }
})

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    '@media (max-width: 1024px)': {
        // Styles spécifiques à une largeur d'écran maximale de 600 pixels
        width: 350, // Par exemple, changer la largeur à 100% pour les petits écrans
        p: 2,
      },
  };

export const Photo = () => {
    const {id} = useParams()
    let [categorie, setCategorie] = useState([])
    let [realisations, setRealisations] = useState([])
    let [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);
    const [openModals, setOpenModals] = useState({});

    useEffect(() => {
        fetch(`https://api.dhquartz.com/api/categrory_realisations/${id}`)
        .then((response) => {
            response = response.json()
            response.then((result) => {
                setCategorie(result)
                setIsLoading(false)
            })})
    }, [])

    useEffect(() => {
        fetch(`https://api.dhquartz.com/api/realisations`)
        .then((response) => {
            response = response.json()
            response.then((result) => {
                setRealisations(result['hydra:member'])
                setIsLoading(false)
            })})
    }, [])

    const toggleModal = (realisationId) => {
        setOpenModals((prevOpenModals) => ({
            ...prevOpenModals,
            [realisationId]: !prevOpenModals[realisationId],
        }));
    };
    const handleClose = () => setOpen(false);

    const handleOpen1 = () => setOpen(true);
    const handleClose2 = () => setOpen(false);

    let constantValue = 3;

    if (windowWidth > 1490) {
        constantValue = 12;
    } else if (windowWidth > 1024) {
        constantValue = 6;
    }

    const handleChangePage = (event, value) => {
        setPage(value)
    }

    const startIndex = (page - 1) * constantValue
    const endIndex = startIndex + constantValue

    console.log(realisations)
    console.log(categorie)


    return(
        <div className="w-full flex flex-col justify-center items-center lg:text-2xl text-lg">
            <Helmet>
                <title>Réalisations de l'entreprise | D.H. Quartz</title>
            </Helmet>
            <Header title={categorie.name} />
            <div className="w-[80%] flex justify-start">
                <Link to={'/photos'} className="bg-[#B4842C] text-white rounded-full py-2 px-5">Retour</Link>
            </div>
            <div className="w-[75%] flex justify-start my-10">
                <p className="lg:text-lg text-sm" dangerouslySetInnerHTML={{ __html: categorie.description }} />
            </div>
            <div className="lg:w-[70%] w-[60%] grid 2xl:grid-cols-4 lg:grid-cols-3 grid-cols-1 gap-x-40 gap-y-10 lg:mt-16 mt-8">
                {isLoading ? "Chargement en cours" : realisations.map(realisation => (
                    realisation.categroryRealisation === "/api/categrory_realisations/"+categorie.id ? 
                        (
                            <ThemeProvider theme={theme}>
                                <div className="flex justify-center items-center w-full lg:h-[250px] h-[200px] relative transition ease-in-out hover:scale-110 duration-300 hover:shadow-2xl">
                                    <Button onClick={() => toggleModal(realisation.id)} tabIndex={realisation.id} id={realisation.title} className='w-full h-full object-cover'><img src={"https://api.dhquartz.com/build/images/" + realisation.image} alt="Test" className='w-full h-full object-cover' /></Button>
                                    <Modal
                                        open={openModals[realisation.id] || false}
                                        onClose={() => toggleModal(realisation.id)}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                        id={realisation.title}
                                        tabIndex={realisation.id}
                                    >
                                        <Box sx={style} className="flex lg:flex-row flex-col justify-between items-center lg:space-y-0 space-y-5">
                                            <div className="lg:w-[50%] w-full">
                                                <img src={"https://api.dhquartz.com/build/images/" + realisation.image} alt="Test" className='w-full max-h-[800px] object-cover' />
                                            </div>
                                            <div className="lg:w-[45%] w-full space-y-6">
                                                <p className="lg:text-2xl text-xl font-bold">{realisation.title}</p>
                                                <p className="lg:text-lg text-sm" dangerouslySetInnerHTML={{ __html: realisation.description }} />
                                            </div>
                                        </Box>
                                    </Modal>
                                </div>
                            </ThemeProvider>
                        )
                    : "")
                )}
            </div>
            <div className="flex flex-row items-center justify-center space-x-2 lg:mt-16 mt-8">
                <ThemeProvider theme={theme}>
                    <Stack spacing={2} justifyContent="center" py={1} px={4} className='bg-white shadow-2xl rounded-full'>
                        {isLoading ? "Chargement en cours" : 
                            <Pagination 
                                count={Math.ceil(realisations.length / constantValue)}
                                page={page}
                                onChange={handleChangePage}
                                color='primary'
                            />
                        }
                    </Stack>
                </ThemeProvider>
            </div>
            <div className="bg-[#B6872D] h-[2px] w-[30%] lg:mt-16 mt-8 rounded-full"></div>
        </div>
    )
}