import { Outlet, Route, Routes } from "react-router-dom"
import Accueil from "./Accueil"
import { Fragment } from "react";
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Contact from "./Contact"
import { Presentation } from "./Presentation";
import { Actualites } from "./Actualites";
import { Acces } from "./Acces";
import { Securite } from "./Securite";
import { Patrimoine } from "./Patrimoine";
import { Temps } from "./Temps";
import { Actualite } from "./Actualite";
import { Photos } from "./Photos";
import { Photo } from "./Photo";
import { Mentions } from "./Mentions";
import { Confidentialite } from "./Confidentialites";

function Layout() {
  return(
    <Fragment>
      <Navbar />
      <Outlet />
      <Footer />
    </Fragment>
  )
}

function Content() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Accueil />}></Route>
        <Route path="presentation" element={<Presentation />}></Route>
        <Route path="gestion-des-acces" element={<Acces />}></Route>
        <Route path="gestion-de-la-securite" element={<Securite />}></Route>
        <Route path="gestion-du-patrimoine" element={<Patrimoine />}></Route>
        <Route path="gestion-des-temps-alarmes-incendies-et-ppms" element={<Temps />}></Route>
        <Route path="photos" element={<Photos />} />
        <Route path="photo/:id" element={<Photo />} />
        <Route path="actualites" element={<Actualites />}/>
        <Route path="actualite/:id" element={<Actualite />} />
        <Route path="contact" element={<Contact />}></Route>
        <Route path="mentions" element={<Mentions />}></Route>
        <Route path="confidentialite" element={<Confidentialite />}></Route>
      </Route>
    </Routes>
  )
}

function App() {
  return (
    <div className="App">
      <Content />
    </div>
  );
}

export default App;
